import React from "react"

const VectorApplePodcast = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.38 48.81">
    <path
      d="m27.02,30.05c-.14-.3-.33-.58-.55-.83-.83-1.1-2.48-1.65-4.69-1.65-1.72-.09-3.41.5-4.69,1.65-.55.49-.85,1.2-.83,1.93-.14,1.56-.14,3.13,0,4.69.27,1.93.55,4.41.83,7.17.15,1.22.43,2.42.83,3.58.58,1.44,2.03,2.33,3.58,2.21,1.55.13,3-.77,3.58-2.21.4-1.16.68-2.36.83-3.58.28-2.76.83-5.24.83-7.17,0,0,.83-4.69.28-5.79m-10.2-9.65c0,2.89,2.34,5.24,5.23,5.24,0,0,0,0,0,0,2.89,0,5.24-2.34,5.24-5.23,0,0,0,0,0,0,0-2.89-2.34-5.23-5.23-5.23,0,0,0,0,0,0-2.89,0-5.24,2.34-5.24,5.23,0,0,0,0,0,0M22.05,0C9.98,0,.16,9.71,0,21.78c-.05,9.52,5.92,18.03,14.89,21.23.28,0,.55,0,.28-.27,0-.83-.28-1.65-.28-2.21-.02-.29-.25-.53-.55-.55-7.08-3.14-11.63-10.17-11.58-17.92C2.76,11.58,11.58,3.03,22.06,2.75c10.72-.09,19.48,8.53,19.57,19.25,0,.02,0,.03,0,.05.12,7.7-4.47,14.69-11.58,17.64-.28,0-.28.28-.55.55,0,.83-.28,1.38-.28,2.21,0,.15.11.27.26.28,0,0,0,0,.01,0,8.87-3.16,14.82-11.54,14.89-20.95C44,9.7,34.14.08,22.05,0m-.55,10.2c6.6-.25,12.15,4.9,12.4,11.49,0,.21.01.43,0,.64.07,3.36-1.34,6.59-3.86,8.82-.21.22-.31.52-.28.83v2.48c0,.28.28.28.55.28,4.09-2.81,6.56-7.44,6.61-12.4-.03-8.26-6.75-14.93-15.01-14.9-.14,0-.28,0-.43,0-7.87.36-14.12,6.74-14.33,14.61-.07,5,2.43,9.68,6.61,12.41.28,0,.55,0,.55-.28v-2.48c.04-.3-.06-.61-.27-.83-2.53-2.34-3.93-5.65-3.86-9.1,0-6.28,5.02-11.42,11.3-11.58"
      fill="currentColor"
    />
  </svg>
)

export default VectorApplePodcast
