// vendors
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { lessThen, greaterThen } from "../../utils/media-query"
import wrapper from "../../utils/wrapper"

const SidebarLayout = ({
  children,
  sidebar,
  sideWidth,
  gap,
  breakpoint = null,
  sideAfter = false,
  ...props
}) => {
  const Content = styled.div`
    max-width: 960px;
    flex-grow: 999;
    margin-left: calc(${gap} / 2);
    margin-right: calc(${gap} / 2);

    ${!breakpoint &&
    `min-width: 50%;
      flex-basis: 0;
    `}

    ${greaterThen(breakpoint)} {
      min-width: 50%;
      flex-basis: 0;
    }
  `

  const Layout = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: calc(${gap} / 2 * -1);
    margin-right: calc(${gap} / 2 * -1);

    ${!breakpoint && `flex-wrap: wrap;`}

    ${lessThen(breakpoint)} {
      flex-wrap: wrap;
    }
  `

  const Sidebar = styled.aside`
    flex-grow: 1;
    flex-basis: ${sideWidth};
    margin-left: calc(${gap} / 2);
    margin-right: calc(${gap} / 2);
    order: ${sideAfter && 1};

    ${lessThen(breakpoint)} {
      order: ${sideAfter && 1};
    }
  `

  return (
    <div
      css={css`
        ${wrapper.bolt()}
      `}
      {...props}
    >
      <Layout>
        <Sidebar>{sidebar}</Sidebar>

        <Content>{children}</Content>
      </Layout>
    </div>
  )
}

export default SidebarLayout
