import React from "react"

const VectorSpotify = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.89 37.03">
    <path
      d="m19.44,0C8.7,0,0,8.29,0,18.52s8.7,18.52,19.44,18.52,19.44-8.29,19.44-18.52S30.18,0,19.44,0Zm8.92,26.71c-.37.55-1.1.72-1.67.38-4.57-2.66-10.31-3.26-17.08-1.78-.64.15-1.28-.23-1.45-.87-.14-.62.25-1.24.88-1.37.01,0,.02,0,.03,0,7.41-1.61,13.76-.92,18.89,2.07.54.3.74.99.44,1.53-.01.02-.02.04-.04.06m2.38-5.04c-.46.69-1.37.89-2.09.48-5.23-3.06-13.19-3.95-19.38-2.16-.79.24-1.62-.19-1.89-.96-.23-.76.2-1.56.96-1.79.02,0,.03,0,.05-.01,7.06-2.04,15.84-1.05,21.84,2.46.68.37.92,1.23.55,1.9-.02.03-.03.06-.05.08h0Zm.2-5.25c-6.27-3.55-16.61-3.87-22.59-2.14-.94.29-1.94-.22-2.27-1.15-.27-.91.24-1.87,1.15-2.14.02,0,.04-.01.07-.02,6.87-1.99,18.28-1.6,25.5,2.48.82.44,1.13,1.46.69,2.28-.02.03-.04.06-.05.09-.54.83-1.63,1.1-2.49.61h0Z"
      fill="currentColor"
    />
  </svg>
)

export default VectorSpotify
